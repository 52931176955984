<template>
    <div class="card-plans">
        <v-container>
          <!-- <v-row>
            <v-col cols="12" md="12" style="text-align: center;">
              <v-btn-toggle
                v-model="type"
                rounded
                color="#00AF7D"
              >
                <v-btn class="button-types" value="monthly">
                  MENSAL
                </v-btn>
                <v-btn class="button-types" value="quarterly">
                  TRIMESTRAL
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row> -->
          <v-row>
            <v-col cols="12" md="6" class="plan">
              <h3>Mensal</h3>
              <h2>R$ 497,00</h2>
              <ul>
                <li>Site de vendas</li>
                <li>portfólio completo 2W +300 modelos disponíveis</li>
                <li>Lançamentos mensais</li>
                <li>Logística direto ao seu cliente</li>
                <li>Personalização da sua marca </li>
                <li>Treinamentos / Central de Associados</li>
                <li>Arquivo de fotos e vídeos para divulgação</li>
              </ul>
              <button class="button-subscribe" @click="selectPlan('monthly', monthly)">
              Assinar
              </button>
            </v-col>

            <v-col cols="12" md="6" class="plan">
              <h3>Trimestral</h3>
              <h2 ><small>3x </small>R$ 347,00</h2>
              <h3 style="font-size: 12pt">Total: R$ 1.041,00</h3>
              <ul>
                <li>Site de vendas</li>
                <li>portfólio completo 2W +300 modelos disponíveis</li>
                <li>Lançamentos mensais</li>
                <li>Logística direto ao seu cliente</li>
                <li>Personalização da sua marca </li>
                <li>Treinamentos / Central de Associados</li>
                <li>Arquivo de fotos e vídeos para divulgação</li>
              </ul>
              <button class="button-subscribe" @click="selectPlan('quarterly', quarterly)">
              Assinar
              </button>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'CardPlans',
    data(){
        return {
              monthly: 497,
              quarterly: 1041
            }
    },
    methods: {
        selectPlan(recurrence, price) {
          recurrence = recurrence === 'monthly' ? 'Mensal' : 'Trimestral'
          this.$emit('selectPlan', { recurrence, price })
        }
    }
}
</script>

<style scoped>

.input-type {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  background-color: #1c1a1d;
  border: 1px solid #fff;

}

.card-plans {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
}

.card-plans .plan {
  padding: 20px;
}

.card-plans .plan h2 {
  font-size: 2.3rem;
  font-weight: 700;
  color: #00AF7D;
}

.card-plans .plan h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  margin-top: -10px;
}

.card-plans .plan p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

.card-plans .plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-plans .plan ul li:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/check.svg') no-repeat 50%;
  background-size: cover;
  margin-right: 10px;
}

.card-plans .plan ul li.no:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/uncheck.svg') no-repeat 50% !important;
  background-size: cover;
  margin-right: 10px;
}

.card-plans .plan ul li.no {
  text-decoration: line-through
}

.card-plans .plan ul li {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

.button-subscribe {
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 25px;
  background-color: #00af7d85;
  cursor: pointer;
  margin-top: 20px;
  transition: .4s ease-in-out;
}

.button-subscribe:hover {
  background-color: #00AF7D;
  color: #fff
}

</style>